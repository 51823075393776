.HudBox .KeyButtons {
	position: absolute;
	top: 21.875vw;
	left: 1.563vw;
	display: flex;
	flex-direction: column;
	gap: 0.521vw;
}
.HudBox .KeyButtons p {
	padding: 0;
	margin: 0;
	display: flex;
	gap: 0.521vw;
	align-items: center;
	font-size: 0.833vw;
	font-weight: 500;
	line-height: 0.985vw;
	text-align: left;
}
.HudBox .KeyButtons p span {
	border-radius: 0.26vw;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.5);
	width: 1.823vw;
	height: 1.823vw;
	font-size: 0.833vw;
	font-weight: 500;
	line-height: 0.985vw;
	text-align: left;
}