.CarStats {
	width: 12.552vw;
	position: absolute;
	right: 1.563vw;
	bottom: 1.563vw;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.CarStats .CarSpeedBox {
	display: flex;
	align-items: center;
	align-items: center;
	width: 10vw;

	justify-content: space-between;
}
.CarStats .CarSpeedBox svg {
	width: 2.083vw;
	height: 1.771vw;
}
.CarStats .CarSpeedBox svg {
	vertical-align: middle; /* Aligns SVGs in the middle */
  }
.CarStats .CarSpeedBox .CarSpeed {
	display: flex;
	flex-direction: column;
}
.CarStats .CarSpeedBox .arch {
	position: relative;
	top: -0.28vw;
	scale: 1.9;
}
.CarStats .CarSpeedBox .CarSpeed p {
	display: flex;
	margin: 0;
	padding: 0;
	font-size: 2.5vw;
	font-style: italic;
	font-weight: 700;
	line-height: 2.955vw;
	text-align: right;
  color: white;
}
.CarStats .CarSpeedBox .CarSpeed p span {
	font-size: 2.5vw;
	font-style: italic;
	font-weight: 400;
	line-height: 2.955vw;
	color: rgba(255, 255, 255, 0.7);
	text-align: right;
}
.CarStats .CarSpeedBox .CarSpeed article {
	font-size: 1.25vw;
	font-style: italic;
	font-weight: 400;
	line-height: 1.478vw;
	letter-spacing: 0.25em;
	text-align: center;
	color: rgba(255, 255, 255, 0.7);
}
.CarStats .CarAtribut {
	display: flex;
	gap: 0.781vw;
	align-items: center;
}
.CarStats .CarAtribut svg {
	height: 1.198vw;
	width: 1.198vw;
}
.CarStats .Carstat {
	display: flex;
	gap: 0.885vw;
	align-items: center;
	height: 2vw;
}
.CarStats .Carstat p {
	display: flex;
	gap: 0.26vw;
	align-items: center;
	font-size: 0.938vw;
	font-style: italic;
	font-weight: 400;
	line-height: 94.858vw;
	text-align: right;
  color: white;
}
.CarStats .Carstat p svg {
	height: 0.781vw;
	width: 0.781vw;
}