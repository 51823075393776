.logo {
    position: absolute;
    top: 1.5vh;   
    right: 2vw; 
    width: 5vw; 
    height: 5vw;
  }
  .server_number {
    font-family: "Mazzard", sans-serif;
    font-weight: 700;
    font-size: 1.2rem;       
    line-height: 1.4rem;     
    width: 3.5vw;                     
    color: rgba(255, 129, 0, 1);
  
    position: absolute;
    top: 10vh; 
    white-space: nowrap;
  
    position: absolute;
    right: 4vw;
    top: 6.5vw;
  }
  
  
  
  .clocks_data {
    position: absolute;
    top: 2vh;   
    right: 10vw; 
    
    display: flex;
    flex-direction: column; 
    gap: 0.8vw;
    align-items: flex-start; 
    margin-top: 10px;
  }
  
  .clocks_data div {
    display: flex;
    align-items: center; 
  }
  
  .time_icon, .clock_icon {
    width: 20px; 
    height: 20px;
    margin-right: 0.2vw; 
  }
  
  .clock_time {
    position: absolute;
    top: 4.8vh;   
    right: -1.8vw; 
  
    font-family: "Mazzard", sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    color: white;;
  }
  
  .time_time {
    font-family: "Mazzard", sans-serif;
    font-style: bold;
    font-weight: 700;
    font-size: 1.5rem;
    margin-left: 0.5rem;
    color: white;
  
    text-align: center;
  }
  .calendar {
    position: absolute;
    top: 4.3vh;   
    right: 4.5vw; 
    opacity: 0.7;
    
    font-family: "Mazzard", sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    color: white;
  
    width: 1.2vw;
    height: 1.2vw;
  }
  
  .money_text {
    position: absolute;
    top: 9vw;
    right: 4.7vw;
  
    color: white;
    line-height: 28px;
    font-size: 1.3rem;
  }
  .money_icon {
    position: absolute;
    top: 9.2vw;
    right: 3vw;
  
    width: 1.3vw;
    height: 1.3vw;
  
    line-height: 28px;
    font-size: 1.3rem;
  }
  
  .star_1 {
    width: 1.3vw;
    height: 1.3vw;
  }
  .star_2 {
    width: 1.3vw;
    height: 1.3vw;
  }
  .star_3 {
    width: 1.3vw;
    height: 1.3vw;
  }
  .star_4 {
    width: 1.3vw;
    height: 1.3vw;
  }
  .star_5 {
    width: 1.3vw;
    height: 1.3vw;
  }
  .star_6 {
    width: 1.3vw;
    height: 1.3vw;
  }
  .image-container {
    position: relative;
    display: inline-block;
  }
  
  .fistbackgroud {
    position: absolute;
    top: 3.75vw;
    right: 20%;
    width: 3.5vw;
    height: 3.5vw;
    transform: translate(50%, -50%);
  }
  
  .weapon {
    position: absolute;
    top: 3.75vw;
    right: 20%;
    width: 2.5vw;
    height: 2.5vw;
    transform: translate(50%, -50%);
  }