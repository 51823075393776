.speedometr {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Все элементы выравниваются по правой стороне */
  justify-content: flex-end; /* Элементы будут прижаты к нижней части */
  height: 100vh; /* Высота на весь экран */
  padding: 1rem; /* Отступы вокруг */
}

/* Стрелки и скорость */
.arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2vh; /* Отступ снизу увеличен, чтобы поднять скорость */
}

.arrow1, .arrow2 {
  width: 4rem;
  height: 4rem;
}

.arrow1 {
  transform: rotate(180deg); /* Стрелка слева будет смотреть влево */
  position: relative;
  top: -3vw;
  left: 32.5vw;
}
.arrow2 {
  position: relative;
  top: -3vw;
  left: 41.5vw;
}

.vehicle_speed {
  color: white;
  font-size: 3rem; /* Размер текста */
  font-weight: bold;
  margin: 0 0.5rem; /* Меньше отступ между стрелками и скоростью */
  position: relative; /* Делаем элемент позиционированным */
  top: -18vh; /* Поднимаем скорость выше */
  right: 8vw;
}

.kmperhour {
  font-size: 1.2rem;
  color: white;
  margin-top: 0.5vh;

  position: relative; /* Делаем элемент позиционированным */
  top: -16vh; /* Поднимаем скорость выше */
  right: 9.5vw;
}

/* Иконки */
.icons {
  display: flex;
  justify-content: space-between;
  width: 12rem; /* Ширина для иконок увеличена */
  margin-bottom: 2vh; /* Увеличено расстояние между иконками и блоком ниже */
  align-items: flex-start; /* Поднимет иконки вверх */
  position: relative;
  top: -2vw;
  right: 6vw;
}


.icons img {
  width: 2.5rem; /* Увеличиваем размер иконок */
  height: 2.5rem;
  margin-right: 1rem; /* Расстояние между иконками увеличено */
}

/* Блок с топливом и пробегом */
.fuel_mileage {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2vh; /* Увеличено расстояние между блоками */
}

.fuel, .mileage {
  color: white;
  font-size: 1.5rem;
}

.fuel_station, .mileage_icon {
  width: 2rem; /* Размер иконок */
  height: 2rem;
  position: absolute; /* Абсолютное позиционирование */
}

.fuel_station {
  left: -2.5rem;
  top: 50%; /* Выравниваем по вертикали */
  transform: translateY(-50%); /* Центрируем по вертикали */
}

/* Позиционирование иконки mileage_icon */
.mileage_icon {
  left: -2.5rem; /* Сдвигаем иконку влево от текста */
  top: 50%; /* Выравниваем по вертикали */
  transform: translateY(-50%); /* Центрируем по вертикали */
}

/* Позиционирование текста fuel и mileage */
.fuel, .mileage {
  position: relative; /* Делаем текст относительным для привязки иконок */
  color: white;
  font-size: 1.5rem;
}

.fuel {
  right: 15vw;
  top: -3vw;
}
.mileage {
  right: 2vw;
  top: -3.75vw;
}

html, body {
  overflow: hidden; /* Полностью отключает прокрутку */
}

* {
  user-select: none;
  user-select: none;
  -webkit-user-select: none; /* Для Safari */
  -moz-user-select: none; /* Для Firefox */
  -ms-user-select: none; /* Для Edge */
}

