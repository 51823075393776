.needs_bar {
  position: fixed;
  bottom: 5%;
  left: 22%; /* Располагаем рядом с миникартой */
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px 15px;
  border-radius: 8px;
  width: 160px;
}

.need_item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
}

.need_icon {
  width: 35px;
  height: 35px;
}

.bars {
  display: flex;
  gap: 1px;
}

.bar {
  width: 6px;
  height: 14px;
  border-radius: 2px;
}

/* 🔥 Цвета баров */
.water .bar { background-color: #00a2ea; }
.hunger .bar { background-color: #EA8C00; }
.health .bar { background-color: #E52D28; }

/* Полупрозрачные полоски */
.bar.inactive {
  opacity: 0.5;
}