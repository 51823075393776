@font-face {
  font-family: "Mazzard";
  src: url("./fonts/Mazzard-Regular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

body {
  font-family: "Mazzard", sans-serif;
  font-size: 10px;
  line-height: 11.82px;
  letter-spacing: 0;
  /* background-color: black; */
  overflow: hidden;
  overflow-y: hidden;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html, body {
  overflow: hidden; /* Полностью отключает прокрутку */
}

* {
  user-select: none;
  user-select: none;
  -webkit-user-select: none; /* Для Safari */
  -moz-user-select: none; /* Для Firefox */
  -ms-user-select: none; /* Для Edge */
}

